<template>
  <div class="vp-max-w-3xl">
    <div>
      <TheHeader
        title="Features of Vepaar Store"
        desc="Start selling online for free. You can use countless features, all of which help you manage your business easily."
      />

      <CardsShell>
        <!-- {Product} -->
        <TheCard
          icon-color="pink"
          title="Analytics"
          :icon="$options.icons.Dashboard"
          desc="Checkout Analytics for important statistics and start with your sales journey"
        />

        <TheCard
          :icon="$options.icons.Cart"
          icon-color="cyan"
          title="Order Management"
          desc="You can edit order details and customers can downloading receipts in your Vepaar store."
        />

        <TheCard
          :icon="$options.icons.Whatsapp"
          icon-color="warning"
          title="WhatsApp Checkout"
          desc="For a quicker checkout, Vepaar allows your clients to place an order via WhatsApp."
        />

        <TheCard
          :icon="$options.icons.Payment"
          icon-color="danger"
          title="Payment Gateway"
          desc="Your customers can have the luxury of choosing from a list of payment gateways from your online shop."
        />

        <TheCard
          :icon="$options.icons.CartAddon"
          icon-color="blue"
          title="Checkout Addons"
          desc="Charge customers extra for additional services like faster delivery, gift wrapping."
        />

        <TheCard
          :icon="$options.icons.Domain"
          icon-color="success"
          title="Custom Domain"
          desc="Using a custom domain makes your store look more professional, memorable, and builds trust with your customers."
        />
      </CardsShell>
    </div>
  </div>
</template>

<script>
import {
  Dashboard,
  Domain,
  CartAddon,
  Whatsapp,
  Payment,
  Cart,
} from "icons/icons.js";
import TheCard from "components/src/features/_card.vue";
import TheHeader from "components/src/features/_header.vue";
import CardsShell from "components/src/features/_cards-shell.vue";
export default {
  components: {
    TheCard,
    TheHeader,
    CardsShell,
  },
  icons: {
    Cart,
    Dashboard,
    Payment,
    Domain,
    CartAddon,
    Whatsapp,
  },
};
</script>
